import React, { useState, useRef } from 'react';
import { useAudio } from './AudioContext';
import backwardIcon from './icons/backward-solid.svg';
import forwardIcon from './icons/forward-solid.svg';
import pauseIcon from './icons/pause-solid.svg';
import playIcon from './icons/play-solid.svg';





export default function FoundMe() {
    const [step, setStep] = useState(1);
    const [className, setClassName] = useState("rui-1");
    const [isPlaying, setIsPlaying] = useState(false);
    const { playSound, stopall, pauseSound } = useAudio();

    const handleskip = () => {
        stopall();
        if (step === 13) {
            setStep(1);
            setClassName("rui-1");
            playSound("one");
        } else {
            setStep(step + 1);
            setClassName("rui-" + (step + 1));
            const soundMap = {
                1: "two",
                2: "three",
                3: "four",
                4: "five",
                5: "six",
                6: "seven",
                7: "eight",
                8: "nine",
                9: "ten",
                10: "eleven",
                11: "twelve",
                12: "thirteen"
            };
            playSound(soundMap[step]);
        }
        setIsPlaying(true);
    }

    const handleback = () => {
        stopall();
        if (step === 1) {
            setStep(13);
            setClassName("rui-13");
            playSound("thirteen");
        } else {
            setStep(step - 1);
            setClassName("rui-" + (step - 1));
            const soundMap = {
                2: "one",
                3: "two",
                4: "three",
                5: "four",
                6: "five",
                7: "six",
                8: "seven",
                9: "eight",
                10: "nine",
                11: "ten",
                12: "eleven",
                13: "twelve"
            };
            playSound(soundMap[step]);
        }
        setIsPlaying(true);
    }

    const handlePause = () => {
        if (isPlaying) {
            pauseSound();
        } else {
            const soundMap = {
                1: "one",
                2: "two",
                3: "three",
                4: "four",
                5: "five",
                6: "six",
                7: "seven",
                8: "eight",
                9: "nine",
                10: "ten",
                11: "eleven",
                12: "twelve",
                13: "thirteen"
            };
            playSound(soundMap[step]);
        }
        setIsPlaying(!isPlaying);
    }

    return (
        <div className="App-header">
            <div className={className}>
            </div>
            <div className="controls">
                <button onClick={handleback}>
                    <img src={backwardIcon} alt="backward" />
                </button>
                <button onClick={handlePause}>
                    <img src={isPlaying ? pauseIcon : playIcon} alt={isPlaying ? "pause" : "play"} />
                </button>
                <button onClick={handleskip}>
                    <img src={forwardIcon} alt="forward" />
                </button>
            </div>
        </div>
    );
}








//https://chivalrous-bard-51c.notion.site/13548ac60e59808c85b1e8eba46cd3a8?v=00c73912a023462b945163b9c188fa26


