import React, { createContext, useContext, useRef } from 'react';

const AudioContext = createContext();

export function AudioProvider({ children }) {
  // Keep track of currently playing sound
  const currentSound = useRef(null);

  const sounds = {
    one: process.env.PUBLIC_URL + '/playlist/1.mp3',
    two: process.env.PUBLIC_URL + '/playlist/2.mp3',
    three: process.env.PUBLIC_URL + '/playlist/3.mp3',
    four: process.env.PUBLIC_URL + '/playlist/4.mp3',
    five: process.env.PUBLIC_URL + '/playlist/5.mp3',
    six: process.env.PUBLIC_URL + '/playlist/6.mp3',
    seven: process.env.PUBLIC_URL + '/playlist/7.mp3',
    eight: process.env.PUBLIC_URL + '/playlist/8.mp3',
    nine: process.env.PUBLIC_URL + '/playlist/9.mp3',
    ten: process.env.PUBLIC_URL + '/playlist/10.mp3',
    eleven: process.env.PUBLIC_URL + '/playlist/11.mp3',
    twelve: process.env.PUBLIC_URL + '/playlist/12.mp3',
    thirteen: process.env.PUBLIC_URL + '/playlist/13.mp3'
  };

  const playSound = (soundName) => {
    if (sounds[soundName]) {
      // If there's no current sound or it's a different song
      if (!currentSound.current || currentSound.current.src !== sounds[soundName]) {
        // Stop current sound if any
        if (currentSound.current) {
          currentSound.current.pause();
          currentSound.current.currentTime = 0;
        }
        // Create and play new sound
        const newSound = new Audio(sounds[soundName]);
        currentSound.current = newSound;
        newSound.play().catch(error => {
          console.log("Audio play failed:", error);
        });
      } else {
        // Resume current sound
        currentSound.current.play();
      }
    }
  };

  const pauseSound = () => {
    if (currentSound.current) {
      currentSound.current.pause();
    }
  };

  const stopall = () => {
    if (currentSound.current) {
      currentSound.current.pause();
      currentSound.current.currentTime = 0;
      currentSound.current = null;
    }
  };

  return (
    <AudioContext.Provider value={{ playSound, pauseSound, stopall }}>
      {children}
    </AudioContext.Provider>
  );
}

export function useAudio() {
  return useContext(AudioContext);
}

